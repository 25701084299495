import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import QRCode from 'qrcode.react';
import abi from './SmartBirraABI.json';
import './ListaBirre.css';
import {Link} from 'react-router-dom';

let web3;
let contract;

function ListaBirre() {
    const [birre, setBirre] = useState([]);
    const [birraDetail, setBirraDetail] = useState(null);
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/ce299919ee3f4318ae357195f176bc06`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = '03e51452cd7d65a02182154c9302ee8f76f5f4aef1dbbe40f21460061f0adc81'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0x0653aa896d9addde9c88814be34ae122485145f1';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchBeers = async () => {
            try {
                await initWeb3();
                const beers = await contract.methods.getAllBeers().call();
                setBirre(beers);
            } catch (error) {
                console.error('Error fetching beers:', error);
            }
        };

        fetchBeers();
    }, []);

    const fetchBirraDetail = async (orderId) => {
        try {
            const birra = await contract.methods.getBirraByOrderId(orderId).call();
            setBirraDetail(birra);
        } catch (error) {
            console.error('Error fetching birra detail:', error);
        }
    };

    return (
        <div className="container">
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Ricerca Birra</h1>
                <input
                    type="text"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    placeholder="Inserisci ID dell'ordine"
                    className="inputStyle"
                />
                <button onClick={() => fetchBirraDetail(orderId)} className="buttonStyle">Ricerca</button>
                {birraDetail && (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th centerText" colSpan="2">Ammostamento</th>
                            <th className="th centerText">Sparge</th>
                            <th className="th centerText" colSpan="2">Ebollizione</th>
                            <th className="th centerText" colSpan="4">Fermentazione</th>
                            <th className="th centerText" colSpan="2">Priming</th>
                            <th className="th"></th>
                        </tr>
                        <tr>
                            <th className="th centerText">Tipo Malto</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">Tipo Luppolo</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">Lievito Code</th>
                            <th className="th centerText">Giorni Fermentazione</th>
                            <th className="th centerText">Giorni Maturazione</th>
                            <th className="th centerText">Saccarosio Code</th>
                            <th className="th centerText">Quantita(g/lt)</th>
                            <th className="th centerText">QR Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td centerText">{birraDetail.ingredients[0]} </td>
                            <td className="td centerText">{birraDetail.temperatura1}°C</td>
                            <td className="td centerText">{birraDetail.temperatura2}°C</td>
                            <td className="td centerText">{birraDetail.ingredients[1]} </td>
                            <td className="td centerText">{birraDetail.temperatura3}°C</td>
                            <td className="td centerText">{birraDetail.temperatura4}°C</td>
                            <td className="td centerText">{birraDetail.ingredients[2]} </td>
                            <td className="td centerText">{birraDetail.giorni[0]} </td>
                            <td className="td centerText">{birraDetail.giorni[1]} </td>
                            <td className="td centerText">{birraDetail.ingredients[3]} </td>
                            <td className="td centerText">{birraDetail.quantita} </td>
                            <td className="td centerText">
                                <Link to={`/dettaglio-birra/${birraDetail.orderId}`}>
                                    <QRCode value={`${window.location.origin}/dettaglio-birra/${birraDetail.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </div>
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Tutte le Birre</h1>
                <table className="tableStyle">
                    <thead>
                    <tr>
                        <th className="th"></th>
                        <th className="th centerText" colSpan="2">Ammostamento</th>
                        <th className="th centerText">Sparge</th>
                        <th className="th centerText" colSpan="2">Ebollizione</th>
                        <th className="th centerText" colSpan="4">Fermentazione</th>
                        <th className="th centerText" colSpan="2">Priming</th>
                        <th className="th"></th>
                    </tr>
                    <tr>
                        <th className="th centerText">N.</th>
                        <th className="th centerText">Tipo Malto</th>
                        <th className="th centerText">°C</th>
                        <th className="th centerText">°C</th>
                        <th className="th centerText">Tipo Luppolo</th>
                        <th className="th centerText">°C</th>
                        <th className="th centerText">°C</th>
                        <th className="th centerText">Lievito Code</th>
                        <th className="th centerText">Giorni Fermentazione</th>
                        <th className="th centerText">Giorni Maturazione</th>
                        <th className="th centerText">Saccarosio Code</th>
                        <th className="th centerText">Quantita(g/lt)</th>
                        <th className="th centerText">QR Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {birre.map((birra, index) => (
                        <tr key={birra.orderId}>
                            <td className="td centerText">{index + 1}</td>
                            <td className="td centerText">{birra.ingredients[0]} </td>
                            <td className="td centerText">{birra.temperatura1}°C</td>
                            <td className="td centerText">{birra.temperatura2}°C</td>
                            <td className="td centerText">{birra.ingredients[1]} </td>
                            <td className="td centerText">{birra.temperatura3}°C</td>
                            <td className="td centerText">{birra.temperatura4}°C</td>
                            <td className="td centerText">{birra.ingredients[2]} </td>
                            <td className="td centerText">{birra.giorni[0]} </td>
                            <td className="td centerText">{birra.giorni[1]} </td>
                            <td className="td centerText">{birra.ingredients[3]} </td>
                            <td className="td centerText">{birra.quantita} </td>
                            <td className="td">
                                <Link to={`/dettaglio-birra/${birra.orderId}`}>
                                    <QRCode value={`${window.location.origin}/dettaglio-birra/${birra.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListaBirre;
