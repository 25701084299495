import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import abi from './SmartBirraABI.json';
import './DettaglioBirra.css';
import {useParams} from 'react-router-dom'; // Import useParams

let web3;
let contract;

function DettaglioBirra() {
    const {orderId} = useParams(); // Extract orderId from URL parameters
    const [birraDetail, setBirraDetail] = useState(null);

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/ce299919ee3f4318ae357195f176bc06`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = '03e51452cd7d65a02182154c9302ee8f76f5f4aef1dbbe40f21460061f0adc81'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0x0653aa896d9addde9c88814be34ae122485145f1';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchBirraDetail = async () => {
            try {
                await initWeb3();
                if (orderId) {
                    const birra = await contract.methods.getBirraByOrderId(orderId).call();
                    setBirraDetail(birra);
                }
            } catch (error) {
                console.error('Error fetching birra detail:', error);
            }
        };

        fetchBirraDetail();
    }, [orderId]); // Add orderId as a dependency for useEffect

    return (
        <div className="container">
            <div className="divStyle">
                <h1>Dettagli Birra</h1>
                {birraDetail ? (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th centerText" colSpan="2">Ammostamento</th>
                            <th className="th centerText">Sparge</th>
                            <th className="th centerText" colSpan="2">Ebollizione</th>
                            <th className="th centerText" colSpan="4">Fermentazione</th>
                            <th className="th centerText" colSpan="2">Priming</th>
                        </tr>
                        <tr>
                            <th className="th centerText">Tipo Malto</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">Tipo Luppolo</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">°C</th>
                            <th className="th centerText">Lievito Code</th>
                            <th className="th centerText">Giorni Fermentazione</th>
                            <th className="th centerText">Giorni Maturazione</th>
                            <th className="th centerText">Saccarosio Code</th>
                            <th className="th centerText">Quantita(g/lt)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">{birraDetail.ingredients[0]} </td>
                            <td className="td">{birraDetail.temperatura1}°C</td>
                            <td className="td">{birraDetail.temperatura2}°C</td>
                            <td className="td">{birraDetail.ingredients[1]} </td>
                            <td className="td">{birraDetail.temperatura3}°C</td>
                            <td className="td">{birraDetail.temperatura4}°C</td>
                            <td className="td">{birraDetail.ingredients[2]} </td>
                            <td className="td">{birraDetail.giorni[0]} </td>
                            <td className="td">{birraDetail.giorni[1]} </td>
                            <td className="td">{birraDetail.ingredients[3]} </td>
                            <td className="td">{birraDetail.quantita} </td>
                        </tr>
                        </tbody>
                    </table>
                ) : (
                    <p>Caricamento in corso...</p>
                )}
            </div>
            {/* Aggiungi il div per le spiegazioni sugli ingredienti */}
            <div className="ingredientsExplanation">
                <h2>Origine degli Ingredienti</h2>
                {birraDetail && birraDetail.ingredients[0] === "Pilsner" && (<img src="/birra_malto1.jpeg" style={{ width: '200px' }} alt="Pilsner Image" />)}
                {birraDetail && birraDetail.ingredients[0] === "Pilsner+Melanoidin" && (<img src="/birra_malto2.jpeg" style={{ width: '200px' }} alt="Pilsner Image" />)}

                <p>
                    La nostra birreria si impegna a utilizzare ingredienti di alta qualità provenienti da fonti
                    affidabili. Ecco un breve riassunto dell'origine degli ingredienti principali:
                </p>
                <ul>
                    <li><strong>Malto:</strong> Il nostro malto di prima qualità proviene da coltivazioni selezionate,
                        garantendo un gusto ricco e un profilo aromatico distintivo alla nostra birra.
                    </li>
                    <li><strong>Luppolo:</strong> I nostri luppoli pregiati sono coltivati con attenzione per fornire
                        amarezza, aroma e carattere distintivo alla nostra varietà di birre.
                    </li>
                    <li><strong>Lievito:</strong> Utilizziamo ceppi di lievito speciali, scelti con cura per conferire
                        caratteristiche uniche alla fermentazione e al sapore della birra.
                    </li>
                    <li><strong>Zucchero:</strong>Lo zucchero utilizzato nel processo di produzione contribuisce alla
                        fermentazione e al bilanciamento del corpo della birra.
                    </li>
                </ul>
                <p>
                    Siamo orgogliosi di offrire ai nostri clienti una birra di alta qualità con ingredienti
                    selezionati con cura e provenienti da fonti affidabili.
                </p>
            </div>
        </div>
    )
        ;
}

export default DettaglioBirra;
