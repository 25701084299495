import React, {useState, useEffect, useRef} from 'react';
import Web3 from 'web3';
import abi from './SmartBirraABI.json';

let web3;
let contract;

function AggiungiBirra() {
    const [birraInfo, setBirraInfo] = useState({ /* ... come prima ... */});
    const [metamaskDetected, setMetamaskDetected] = useState(true);
    const [selectedPhase, setSelectedPhase] = useState('AMMOSTAMENTO'); // Stato iniziale
    const ownerAddress = '0x3259E0C016E8061802d504Da0bA13728f6D08E6c'; // Indirizzo del proprietario
    const [isOwner, setIsOwner] = useState(false);

    const audioRef = useRef();


    useEffect(() => {
        const initWeb3 = async () => {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({method: 'eth_requestAccounts'});
                    web3 = new Web3(window.ethereum);

                    const accounts = await web3.eth.getAccounts();
                    console.log('Indirizzo dell\'utente connesso:', accounts[0]);
                    if (accounts[0].toLowerCase() === ownerAddress.toLowerCase()) {
                        // L'utente connesso è il proprietario
                        setIsOwner(true);
                    } else {
                        setIsOwner(false);
                    }
                    console.log("Test", setIsOwner)
                } catch (error) {
                    console.error('User denied account access');
                }
            } else if (window.web3 && window.web3.currentProvider) {
                web3 = new Web3(window.web3.currentProvider);
            } else {
                console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
                setMetamaskDetected(false);
                return;
            }

            const address = '0x0653aa896d9addde9c88814be34ae122485145f1';
            if (web3) {
                contract = new web3.eth.Contract(abi, address);
            }
        };

        initWeb3();
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setBirraInfo({
            ...birraInfo,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const accounts = await web3.eth.getAccounts();
            const birraInput = {
                orderId: parseInt(birraInfo.orderId, 10),
                ingredients: [birraInfo.malto, birraInfo.luppolo, birraInfo.lievito, birraInfo.zucchero],
                temperatura1: parseInt(birraInfo.temperatura1, 10),
                temperatura2: parseInt(birraInfo.temperatura2, 10),
                temperatura3: parseInt(birraInfo.temperatura3, 10),
                temperatura4: parseInt(birraInfo.temperatura4, 10),
                giorni: [parseInt(birraInfo.giorniFermentazione, 10), parseInt(birraInfo.giorniMaturazione, 10)],
                quantita: parseInt(birraInfo.quantita, 10),
            };

            console.log('birraInput:', birraInput);

            const gasPrice = await web3.eth.getGasPrice();

            await contract.methods.addBirra(
                birraInput.orderId,
                birraInput.ingredients,
                birraInput.temperatura1,
                birraInput.temperatura2,
                birraInput.temperatura3,
                birraInput.temperatura4,
                birraInput.giorni,
                birraInput.quantita
            ).send({
                from: accounts[0],
                gas: 2100000,
                gasPrice: gasPrice
            });

            console.log('Dati della birra inviati:', birraInfo);

            setBirraInfo({
                orderId: '',
                malto: '',
                temperatura1: '',
                temperatura2: '',
                luppolo: '',
                temperatura3: '',
                temperatura4: '',
                lievito: '',
                giorniFermentazione: '',
                giorniMaturazione: '',
                zucchero: '',
                quantita: '',
            });
            audioRef.current.play();
        } catch (error) {
            console.error('Errore nell’invio dei dati:', error.message);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            {(!isOwner) && (
                <div
                    style={{
                        backgroundColor: 'rgba(251, 189, 40, 0.6)',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '10px', // Riduci lo spazio superiore
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '600px',
                        margin: '10px auto',
                    }}
                >
                    ATTENZIONE: Solo il proprietario per utilizzare questa funzione!
                </div>
            )}
            <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                <img
                    src="/robot1.png"
                    alt="Robot"
                    style={{
                        width: '250px',
                        height: '250px',
                        position: 'absolute',
                        bottom: '-20px', // Set this to align the image to the bottom
                        right: '700px',
                        zIndex: 1
                    }}
                />
                <img
                    src="/robot2.png"
                    alt="Robot 2"
                    style={{
                        width: '100px',
                        height: '100px',
                        position: 'absolute',
                        top: '20px',
                        right: '80px',
                        zIndex: 1
                    }}
                />
                <div
                    style={{
                        backgroundColor: 'rgba(251, 189, 40, 0.6)',
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '640px',
                        margin: '20px',
                        marginLeft: 'auto',
                        marginRight: '100px',
                        textAlign: 'center',
                        marginTop: '60px',
                        height: '500px', // Imposta un'altezza fissa desiderata
                        overflow: 'auto', // Aggiunge una barra di scorrimento se il contenuto supera l'altezza
                        position: 'relative', // Imposta la posizione relativa
                        width: "640px",
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="SmartBirra Logo"
                        style={{
                            marginBottom: '5px',
                            maxWidth: '200px',
                        }}
                    />

                    <div>
                        <div className="mb-2" style={{display: 'flex', alignItems: 'center', marginLeft: '10%'}}>
                            <label htmlFor="orderId" className="form-label" style={{color: 'white', width: '180px'}}>Order
                                ID</label>
                            <input
                                type="number"
                                className="form-control"
                                id="orderId"
                                name="orderId"
                                value={birraInfo.orderId}
                                onChange={handleInputChange}
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    border: '1px solid white',
                                    width: '80px'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'AMMOSTAMENTO' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('AMMOSTAMENTO')}
                        >
                            AMMOSTAMENTO
                            {selectedPhase === 'AMMOSTAMENTO' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'SPARGE' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('SPARGE')}
                        >
                            SPARGE
                            {selectedPhase === 'SPARGE' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'EBOLLIZIONE' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('EBOLLIZIONE')}
                        >
                            EBOLLIZIONE
                            {selectedPhase === 'EBOLLIZIONE' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'FERMENTAZIONE' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('FERMENTAZIONE')}
                        >
                            FERMENTAZIONE
                            {selectedPhase === 'FERMENTAZIONE' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'PRIMING' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('PRIMING')}
                        >
                            PRIMING
                            {selectedPhase === 'PRIMING' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                    </div>


                    <br></br>
                    {/* FASE AMMOSTAMENTO */}
                    {selectedPhase === 'AMMOSTAMENTO' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="malto" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Malto</label>

                                <select
                                    id="malto"
                                    name="malto"
                                    value={birraInfo.malto} onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px',

                                    }}>
                                    <option
                                        value=""
                                        disabled
                                        selected>
                                        Seleziona malto:</option>
                                    <option value="Pilsner">Pilsner</option>
                                    <option value="Pilsner+Melanoidin">Pilsner+Melanoidin</option>
                                </select>

                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura1"
                                    name="temperatura1"
                                    value={birraInfo.temperatura1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE SPARGE */}
                    {selectedPhase === 'SPARGE' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura2"
                                    name="temperatura2"
                                    value={birraInfo.temperatura2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE EBOLLIZIONE */}
                    {selectedPhase === 'EBOLLIZIONE' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="luppolo" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Luppolo</label>
                                <select
                                    id="luppolo"
                                    name="luppolo"
                                    value={birraInfo.luppolo} onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px',

                                    }}>
                                    <option
                                        value=""
                                        disabled
                                        selected>
                                        Seleziona luppolo:
                                    </option>
                                    <option value="Magnum+Saaz">Magnum+Saaz</option>
                                    <option value="Magnum+Saaz+Cascade">Magnum+Saaz+Cascade</option>
                                </select>
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura3" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura3"
                                    name="temperatura3"
                                    value={birraInfo.temperatura3}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE FERMENTAZIONE */}
                    {selectedPhase === 'FERMENTAZIONE' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="temperatura4" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="temperatura4"
                                    name="temperatura4"
                                    value={birraInfo.temperatura4}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="lievito" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Lievito</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lievito"
                                    name="lievito"
                                    value={birraInfo.lievito}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="giorniFermentazione" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Giorni Fermentazione</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="giorniFermentazione"
                                    name="giorniFermentazione"
                                    value={birraInfo.giorniFermentazione}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="giorniMaturazione" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Giorni Maturazione</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="giorniMaturazione"
                                    name="giorniMaturazione"
                                    value={birraInfo.giorniMaturazione}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE PRIMING */}
                    {selectedPhase === 'PRIMING' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="zucchero" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Saccarosio</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="zucchero"
                                    name="zucchero"
                                    value={birraInfo.zucchero}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="quantita" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Quantita(g/lt)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="quantita"
                                    name="quantita"
                                    value={birraInfo.quantita}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}


                    <hr
                        style={{
                            borderColor: 'white',
                            borderStyle: 'dashed',
                            borderWidth: '2px',
                            position: 'absolute', // Imposta la posizione assoluta
                            bottom: '55px', // Posiziona l'elemento 30px sopra il bottone "Aggiungi Birra"
                            left: '50%', // Centra l'elemento orizzontalmente
                            transform: 'translateX(-50%)', // Centra l'elemento orizzontalmente
                            width: '100%', // Imposta la larghezza al 100% per coprire tutto il contenitore
                        }}
                    />

                    <button type="submit" className="btn btn-light" style={{
                        color: 'black',
                        marginTop: '10px',
                        position: 'absolute', // Imposta la posizione assoluta
                        bottom: '20px', // Posiziona il bottone in fondo al div
                        left: '50%', // Centra il bottone orizzontalmente
                        transform: 'translateX(-50%)', // Centra il bottone orizzontalmente
                    }}>
                        Aggiungi Birra
                    </button>
                </div>
                <audio ref={audioRef} src="/sound.mp3" preload="auto"></audio>
            </div>
        </form>
    );
}

export default AggiungiBirra;
