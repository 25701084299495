import React, { useState } from 'react'; // Importa useState da React
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CustomNavbar from './Navbar';
import Home from './Home';
import Partner from './Partner';
import AggiungiBirra from './AggiungiBirra'; // Importa il componente AggiungiBirra
import ListaBirre from './ListaBirre'; // Importa il componente ListaPizze
import Document from './Documents'; // Importa il componente Document
import DettaglioBirra from './DettaglioBirra'; // Importa il componente DettaglioBirra

function AppRouter() {
  const [birraDetail, setBirraDetail] = useState(null); // Inizializza birraDetail utilizzando useState

  return (
    <Router>
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/aggiungi-birra" element={<AggiungiBirra />} /> {/* Aggiungi la route per AggiungiBirra */}
        <Route path="/lista-birre" element={<ListaBirre />} /> {/* Aggiungi la route per ListaBirre */}
        <Route path="/dettaglio-birra/:orderId" element={<DettaglioBirra />} />
        <Route path="/whitepaper" element={<Document />} /> {/* Add this line */}

      </Routes>
    </Router>
  );
}

export default AppRouter;
